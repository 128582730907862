<template>
  <div id="comment">
    <!-- 修改弹框 -->
    <el-dialog
      v-if="commentFormTitle === '评论修改'"
      :title="commentFormTitle"
      width="500px"
      :visible.sync="commentDialogVisible"
      :close-on-click-modal="false"
      @close="commentDialogClose"
    >
      <el-form
        ref="commentFormRef"
        :model="commentForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col>
            <el-form-item label="发布日期">
              <el-input v-model="commentForm.createTime" readonly />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="所属营地">
              <el-input v-model="commentForm.campName" readonly />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="所属动态">
              <el-input v-model="commentForm.momentsText" readonly />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="发表用户">
              <el-input v-model="commentForm.username" readonly />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="评论内容">
              <el-input v-model="commentForm.text" readonly />
            </el-form-item>
          </el-col>
          <el-col style="height: 62.8px">
            <el-form-item label="是否显示" prop="display">
              <el-radio-group v-model="commentForm.display">
                <el-radio :label="1">
                  显示
                </el-radio>
                <el-radio :label="-1">
                  不显示
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="commentDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="commentFormSubmit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog
      v-if="commentFormTitle === '评论详情'"
      :title="commentFormTitle"
      width="500px"
      :visible.sync="commentDialogVisible"
      :close-on-click-modal="false"
      @close="commentDialogClose"
    >
      <el-form
        ref="commentFormRef"
        :model="commentForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col>
            <el-form-item label="发布日期">
              <span>{{ commentForm.createTime || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="所属营地">
              <span>{{ commentForm.campName || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="所属动态">
              <span>{{ commentForm.momentsText || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="发表用户">
              <span>{{ commentForm.username || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="评论内容">
              <span>{{ commentForm.text || '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col style="height: 62.8px">
            <el-form-item label="是否显示" prop="display">
              <el-radio-group v-model="commentForm.display">
                <el-radio :label="1">
                  显示
                </el-radio>
                <el-radio :label="-1">
                  不显示
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="commentDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="commentFormSubmit">
          提 交
        </el-button>
      </div>
    </el-dialog>
    <!-- 搜索区 -->
    <el-form inline size="small">
      <el-form-item label="评论内容">
        <el-input
          v-model="searchForm.text"
          placeholder="请输入评论内容"
          clearable
          @keyup.enter.native="handleSearch"
        />
        <el-input type="text" style="display: none" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table
      :data="commentPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column
        prop="id"
        label="评论ID"
        width="80"
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="发布日期"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.createTime" :title="scope.row.createTime" style="display: block; width: 200px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-align: center">{{ scope.row.createTime }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="text"
        label="评论内容"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span :title="scope.row.text" style="display: block; width: 150px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-align: center">{{ scope.row.text }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="momentsText"
        label="所属动态"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <span :title="scope.row.momentsText" style="display: block; width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-align: center">{{ scope.row.momentsText }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="campName"
        label="所属营地"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.campName" :title="scope.row.campName" style="display: block; width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-align: center">{{ scope.row.campName }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="评论发表用户" align="center" />
      <el-table-column
        prop="display"
        label="是否显示"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.display === 1">是</span>
          <span v-if="scope.row.display === -1">否</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      :total="commentPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { selectCommentInfo, updateComment, selectCommentList, deleteComment } from '@/api/business/comment'

export default {
  data () {
    return {
      commentDialogVisible: false,
      commentFormTitle: '',
      commentForm: {
        id: '',
        createTime: '',
        text: '',
        momentsText: '',
        name: '',
        campName: '',
        username: '',
        display: ''
      },
      commentPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        id: '',
        text: '',
        momentsId: ''
      }
    }
  },
  created () {
    if (this.$route.params.momentsId) {
      this.searchForm.momentsId = this.$route.params.momentsId
    }
    selectCommentList(this.searchForm).then((res) => {
      this.commentPage = res
    })
  },
  methods: {
    // 点击搜索触发的回调
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCommentList(this.searchForm).then((res) => {
        this.commentPage = res
      })
    },
    // 修改当前页触发的回调
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCommentList(this.searchForm).then((res) => {
        this.commentPage = res
      })
    },
    // 每页展示的数据发生改变时触发的回调
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCommentList(this.searchForm).then((res) => {
        this.commentPage = res
      })
    },
    commentDialogClose () {
      this.$refs.commentFormRef.resetFields()
    },
    commentFormSubmit () {
      if (this.commentFormTitle === '认领详情') {
        this.commentDialogVisible = false
        return
      }
      this.$refs.commentFormRef.validate(async (valid) => {
        console.log('2123' + valid)
        if (valid) {
          console.log('2123' + this.commentFormTitle === '修改')
          if (this.commentFormTitle === '评论修改') {
            await updateComment(this.commentForm)
          }
          this.commentPage = await selectCommentList(this.searchForm)
          this.commentDialogVisible = false
        }
      })
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteComment(row.id)
        if (this.commentPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.commentPage = await selectCommentList(this.searchForm)
      })
    },
    // 点击详情触发的回调
    handleInfo (index, row) {
      this.commentFormTitle = '评论详情'
      this.commentDialogVisible = true
      this.selectCommentInfoById(row.id)
    },
    // 点击修改触发的回调
    handleUpdate (index, row) {
      this.commentFormTitle = '评论修改'
      this.commentDialogVisible = true
      this.selectCommentInfoById(row.id)
    },

    selectCommentInfoById (id) {
      selectCommentInfo(id).then((res) => {
        this.commentForm.id = res[0].id
        this.commentForm.createTime = res[0].createTime
        this.commentForm.text = res[0].text
        this.commentForm.momentsText = res[0].momentsText
        this.commentForm.campName = res[0].campName
        this.commentForm.username = res[0].username
        this.commentForm.display = res[0].display
        this.commentForm.introduce = res[0].introduce
      })
    }
  }
}
</script>

<style></style>
