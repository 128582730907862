import axios from '@/common/axios'
import qs from 'qs'

export function selectCommentList (query) {
  return axios({
    method: 'get',
    url: '/moments/commentListWeb',
    params: query
  })
}

export function selectCommentInfo (id) {
  return axios({
    method: 'get',
    url: '/moments/commentListWeb/' + id
  })
}

export function updateComment (data) {
  return axios({
    method: 'put',
    url: '/moments/updateCommentSHow',
    data: qs.stringify(data)
  })
}

export function deleteComment (id) {
  return axios({
    method: 'delete',
    url: '/moments/deleteComment',
    params: { detailId: id }
  })
}
